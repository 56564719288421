
.logo-container img {
    /*width: 100%;
    height: auto;*/
    max-height: 35px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mainContentBody {
    padding: 16px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.customOverflowText {
}

.customSelectedMenuItem {
    color: white;
    background-color: #007bff;
}

.customMenuItem {
    color: white;
    opacity: 1.5;
    text-decoration: none;
}

.customMenuItem:hover {
    opacity: 0.8
}

ul li {
    list-style: none;
}

.listStyleNone {
    list-style: none;
}

.react-datepicker {
    display: flex !important;
}

.hoverableItem:hover {
    background-color: rgba(0, 0, 0, .03);
    cursor: pointer;
    box-shadow: 0 0 2px #ddd;
}

.lead-remark-list-item {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 1px -1px rgba(9, 30, 66, .25), 0 0 0 1px rgba(9, 30, 66, .08);
    box-sizing: border-box;
    clear: both;
    display: inline-block;
    margin: 2px 2px 6px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.lead-remark-list-item-text {
    display: block;
    padding: 3px 12px;
}

.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 60px;
    z-index: 10;
}

.badge-notify {
    position: relative;
    top: -15px;
    left: -12px;
    font-size: .6em;
}

.blink {
    -webkit-animation: blink 1.5s step-end infinite;
    -moz-animation: blink 1.5s step-end infinite;
    -o-animation: blink 1.5s step-end infinite;
    animation: blink 1.5s step-end infinite;
}

@-webkit-keyframes blink {
    67% {
        opacity: 0
    }
}

@-moz-keyframes blink {
    67% {
        opacity: 0
    }
}

@-o-keyframes blink {
    67% {
        opacity: 0
    }
}

@keyframes blink {
    67% {
        opacity: 0
    }
}

.center-window {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/**designer 001**/
.primary {
    color: #007bff;
}

.account-specific { /*position:relative;top:-70px;*/
}

.acct-title {
    background: DodgerBlue;
    color: #fff;
    padding: 10px 10px 10px 20px;
}

.fs-50 {
    font-size: 50px !important;
    text-align: center !important;
}

.custom-container.container {
    width: 90%;
    position: absolute;
    left: 5%;
}

.item-specific {
    font-size: 12px;
    text-align: center;
}

.scroller {
    margin: 0 auto;
    max-height: 350px;
    overflow: auto;
}

.relative {
    position: relative;
}

.pdf-border {
    border: 3px solid transparent;
    display: inline-block;
    transition: all 0.4s ease-out;
}

img.download-icon {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    opacity: 0;
    transition: all 0.4s ease-out;
}

.pdf-border:hover {
    opacity: 0.7;
    border: 3px solid #21afdf;
    cursor: pointer;
}

.pdf-border:hover img.download-icon {
    opacity: 1;
}

.radius-15 {
    border-radius: 15px !important;
}

.no-border-bottom {
    border-bottom: none !important;
}

.btr-15 {
    border-top-right-radius: 15px !important;
}

.btlr-15 {
    border-top-left-radius: 15px !important;
}

.card-bottom {
    font-weight: bold;
    color: #647279;
    letter-spacing: 1px;
    text-align: center;
    padding-top: 15px;
}

/**designer 001**/

/**FAQ FILE CSS STARTS**/
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.Snapbtn {
    border: 2px solid #007bff;
    color: #007bffa8;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

#Old_uploads {
    margin-top: 55px;
}

#btn_min_width {
    min-width: 151px;
}

#tran_page_btn {
    min-width: 250px;
}

#other_header {
    /*    background-color: #007bff;
    padding: 16px 16px;
    color: #FFF;
    border-bottom: 2px dashed #007bff;*/
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

/**FAQ FILE CSS ENDS**/
.hand-cursor {
    cursor: pointer;
}

.default-cursor {
    cursor: default;
}

.star-selected {
    color: #ffd60d;
    opacity: 0.9;
}

.quil-editor-root {
}

.quil-editor-root .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border: none;
}

.quil-editor-root .ql-editor {
    min-height: 18em;
}

.quil-editor-root .ql-editor img {
    min-height: 18em;
    max-width: 100%;
    height: auto;
}

/* Snow Theme */
.quil-editor-root .ql-snow.ql-toolbar {
    display: block;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.ql-editor {
    font-size: 1rem;
    font-weight: 400;
}

.desc-area .ql-editor {
    padding-left: 0 !important;
}

/* Bubble Theme */
.quil-editor-root .ql-bubble .ql-editor {
    /*border: 1px solid #ccc;*/
    border-radius: 0.5em;
}

/***for overlapping issue ***/
.list-group-item.active {
    z-index: 0 !important;
}

/***for overlapping issue  ***/

.card-minimum {
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.25rem;
}

/**********/

.br-10 {
    border-radius: 10px !important;
}

.h-55 {
    height: 55px !important;
}

.w-150 {
    width: 150px !important;
}

#output_image {
    max-width: 300px;
}

/**********/

/*** USER INBOX CHECKBOXES***/
.inbox-checkboxes {
    display: block;
    position: relative !important;
    padding-left: 35px !important;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 3px !important;
    height: 20px;
    width: 18px;
}

.inbox-icons {
    display: inline-block;
    padding-left: 10px;
    padding-top: 4px;
}

/*** USER INBOX CHECKBOXES***/

.table_Stripping {
    background-color: #c5d9f1 !important;
}

.dashboard_boxes {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.7rem !important;
}

.windoware-logo {
    max-width: 100%;
    height: auto;
}

.custom-navbar-toggler {
    padding: 0.10rem 0.50rem;
    font-size: 1.25rem;
    line-height: 0.2;
    background-color: transparent;
    border-radius: 0.25rem;
    color: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.1);

}

.custom-navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar__link--active {
    border-bottom: 3px solid #1890ff;
    padding-bottom: 5px;
}

.schedule-datepicker{
    width:90px;
}

.cursor-pointer{
    cursor:pointer;
}

.center-row{ display: flex; align-items: center;}